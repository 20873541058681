@use '~@weareopenr/component-library/build/theme.scss' as *;

.header-gradient {
  background: $openr-gradient;
  height: 16px;
  width: 100%;
}

.title {
  margin: $spacing-8 40px;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
}

.search-wrapper {
  margin-right: 40px;
  display: flex;
  align-items: center;
}
