@use '~@weareopenr/component-library/build/theme.scss' as *;

.toggle-container {
  border-radius: 37px;
  min-width: 120px;
  height: 52px;
  display: block;
  padding: $spacing-2;
  background-color: $salt;
  border: 2px solid $squid-ink;
  &:hover {
    cursor: pointer;
  }
}

.checked-toggle-container {
  background-color: $kale;
  border: none;
}

.toggle {
  margin: -2px;
  display: flex;
  align-items: center;
}

.checked-toggle {
  margin: 0;
}

.toggle-text {
  padding: $spacing-1;
  position: absolute;
  margin-left: 55px;
  color: $squid-ink;
}

.checked-toggle-text {
  margin-left: 11px;
  color: $salt;
}

.slider {
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 42px;
  background-color: $squid-ink;
  margin-left: 0;
  transition: 0.3s;
}

.checked-slider {
  margin-left: 68px;
  background-color: $salt;
  margin-bottom: 0;
}

.spinner-container {
  padding-top: 5px;
}

.spinner-container > span {
  width: 25px !important;
  height: 25px !important;
}

.spinner-on > span {
  color: $salt;
  margin-left: -80px;
}

.spinner-off > span {
  color: $squid-ink;
  margin-left: 20px;
}