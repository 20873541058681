@import '~@weareopenr/component-library/build/theme.scss';

.user-dropdown-container {
  background-color: white;
  border-radius: $border-radius;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.08));
  position: absolute;
  left: 28px;
  bottom: 11%;
  animation: fadeIn 300ms forwards;
  transform: none;
  min-width: 222px;
  min-height: 156px;
  z-index: 2;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transition: opacity 266ms cubic-bezier(.17,.67,.83,.67) 0ms, transform 177ms cubic-bezier(.17,.67,.83,.67) 0ms;
    transform-origin:  bottom left 350px;
  }
}
.user-dropdown-closed {
  opacity: 0;
  transform: scale(0);
  transition: opacity 266ms cubic-bezier(.17,.67,.83,.67) 0ms, transform 177ms cubic-bezier(.17,.67,.83,.67) 0ms;

}

.user-dropdown {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.user-details {
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  padding: $spacing-4 ;
}

.logout-button-container {
  &:hover{
    background-color: rgba(0, 0, 0, 0.04);
  }
  cursor: pointer;
}

.text-wrapper {
  margin-left: 9px;
}

.logout-text {
  color: $tomato;
}

.logout-button {
  display: block;
  padding: 21px 50px 21px 20px;
  text-decoration: none;
  width: 100%;
}
