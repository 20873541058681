@use '~@weareopenr/component-library/build/theme.scss' as *;

.schedule-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: $spacing-4;
}
  
.schedule-text {
  color: $kale;
}
  
.scheduled-product-container {
  display: flex;
  align-items: center;
  padding: $spacing-2;
  background-color: $broccoli;
  width: 176px;
  min-height: 56px;
  border-radius: $border-radius;
  margin-left: $spacing-2;
}
  
.close-icon {
  height: 24px;
  width: 24px;
  margin: $spacing-2;
}
