@use '~@weareopenr/component-library/build/theme.scss' as *;

.button-tab {
    position: relative;
    margin-right: 40px;
    margin-bottom:2px;
    background-color: $salt;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
  
  .tab-text {
    font-family: Poppins, sans-serif;
    &:hover {
      color: $kale
    }
  }
  
  .selected {
    @extend .button-tab;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -4px;
      width: 100%;
      height: 4px;
      background-color: $kale;
      border-radius: 5rem;
    }
  }
  
  .selected-text {
    color: $kale;
  }