@use '~@weareopenr/component-library/build/theme.scss' as *;

.page-container {
  position: relative;
}

.products-table {
  border-collapse: separate;
  border-spacing: 0 $spacing-8;
  margin: -$spacing-8 40px 0;
  width: calc(100% - 80px);
  position: absolute;
}

.product-heading {
  font-weight: 600;

  &:first-of-type {
    padding-left: $spacing-4;
    max-width: 500px;
  }
}

.product-row {
  height: 96px;
  margin-right: 40px;
}

.product-cell {
  border: 1px solid $mint;
  border-left: none;
  border-right: none;
  width: 25%;

  &:first-of-type {
    border: 1px solid $mint;
    border-right-style: none;
    padding-left: $spacing-4;
    border-radius: $border-radius 0 0 $border-radius;
  }

  &:last-of-type {
    border: 1px solid $mint;
    border-radius: 0 $border-radius $border-radius 0;
    border-left-style: none;
  }
}

.brand-wrapper {
  align-items: center;
  display: flex;
  height: $spacing-8;
  width: 75px;
}

.brand-image {
  margin-right: $spacing-2;
}

.spinner-container {
  position: fixed;
  left: 50%;
  top: 50%;
}

.availability-container {
  display: flex;
  align-items: center;
}
