@use '~@weareopenr/component-library/build/theme.scss' as *;

.icon-button {
  align-items: center;
  background-color: $salt;
  border-color: transparent;
  border-radius: $border-radius;
  color: $squid-ink;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 96px;
  width: 96px;
}

.active-button {
  background-color: $kale;
}

.active-text {
  color: $salt;
}

.active-filter {
  filter: brightness(0) saturate(100%) invert(100%) sepia(16%) saturate(2938%) hue-rotate(226deg)
    brightness(125%) contrast(111%);
}

.icon-text {
  margin-top: $spacing-2;
}
