@use '~@weareopenr/component-library/build/theme.scss' as *;

.products-layout {
  margin-left: 120px;
}

.spinner-container {
  position: fixed;
  left: 50%;
  top: 50%;
}

.search-result {
  padding-left: 40px;
  padding-bottom: 30px;
}

.tab-container {
  margin: 0 0 40px 40px;
}
