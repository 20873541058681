@use '~@weareopenr/component-library/build/theme.scss' as *;

.avatar {
  align-items: center;
  background-color: #e4d9e5;
  border: none;
  border-radius: 50%;
  display: flex;
  font-family: inherit;
  justify-content: center;
  min-height: 64px;
  min-width: 64px;
}

.avatar-hover {
  &:hover {
    background-color: #e4d9e5;
    cursor: pointer;
  }
}

.initials {
  color: $aubergine;
}
