@use '~@weareopenr/component-library/build/theme.scss' as *;

.sidebar {
  align-items: center;
  background-color: $salt;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  left: 0;
  max-height: 100vh;
  min-height: 100%;
  position: fixed;
  top: 0;
  width: 120px;
  z-index: 1;
}

.logo-icons-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: $spacing-6;
  min-height: 180px;
}

.icon-text {
  margin-top: $spacing-2;
}

.avatar-wrapper {
  margin-top: auto;
  margin-bottom: $spacing-6;
}
