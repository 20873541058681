@import '~@weareopenr/component-library/build/theme.scss';
$tablet-breakpoint: 534px;

.modal-title {
  margin-bottom: $spacing-2;
}

.radio-container {
  margin: 40px 0 $spacing-8;
}

.text-field {
  width: 500px;
  margin: $spacing-8 0;
}
  
.action-content {
  width: 500px;
  display: flex;
  column-gap: $spacing-3;
  margin-top: $spacing-8;

  @media (max-width: $tablet-breakpoint) {
    flex-direction: column;
    row-gap: $spacing-5;
    width: 100%;
  }
}

.semi-bold {
  font-weight: 600;
}

div[role='rowgroup'] > div[role='row'] > .selected-day {
  background-color: $kale;

  &:hover {
    background-color: $kale;
  }
}

.schedule-icon {
  height: 20px;
  width: 20px;
  margin: $spacing-2;
}